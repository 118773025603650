(function($) {

    function init_pet_sliders() {
        $('.pet__slideshow').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.pet__thumbnails'
        });

        $('.pet__thumbnails').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: '.pet__slideshow',
            dots: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                }
            ]
        });

        $('.widget_pets.section .pets').slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: false,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    $(document).ready(function() {
       init_pet_sliders();
    });

    $(window).resize(function() {
        $('.pet__thumbnails').slick.refresh;
        $('.pet__slideshow').slick.refresh;
    });

})(jQuery);